const WEBROOT = "https://www.zawajmouslims.com/"
const API_ROOT = "https://www.zawajmouslims.com/api/"
const AVATAR_ROOT = "https://www.zawajmouslims.com/image/profile/"

const AVATAR_HOMME = "/image/avatar-homme.jpg"
const AVATAR_FEMME = "/image/avatar-femme.jpg"

const APP_NAME = "Zawaj Mouslims"

// API

const API_SECRET = "123123"

export { WEBROOT, API_ROOT, APP_NAME, API_SECRET, AVATAR_ROOT, AVATAR_HOMME, AVATAR_FEMME}