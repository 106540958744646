import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, Link  } from 'react-router-dom';

import User from '../class/User'
import Discussions from '../class/Discussions'

import { Header, Footer } from '../components/imports'
import { Card, BigCard } from '../components/Components'

import { APP_NAME } from '../constants/general'

import { Context } from '../class/Context'


const search = (setUsers, setLoadingUsers) => {
    
    const search_input = document.querySelector('#search_input')

    User.getUsersHome(setUsers, setLoadingUsers, { search: search_input.value})

}

const filterFunc = (e, setUsers, setLoadingUsers) => {

    e.preventDefault()

    const input = e.target

    var data = {
        age_min: input.age_min.value,
        age_max: input.age_max.value,
        taille_min: input.taille_min.value,
        taille_max: input.taille_max.value,
        poid_min: input.poid_min.value,
        poid_max: input.poid_max.value
    }

    User.getUsersHome(setUsers, setLoadingUsers, data)

}

const getMyDiscussions = async (setMyDiscussions, setLoadingDiscussions) => {

    let discussions = await Discussions.myDiscussions()

    //console.log(discussions)

    if (discussions.success) {

        setLoadingDiscussions(false)

        setMyDiscussions(discussions.data)

    } else {

        //navigate('/404')

        //alert(discussions.message)

    }

}

function Acceuil() {

    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [loadingUsers, setLoadingUsers] = useState(true)
    const [loadingDiscussions, setLoadingDiscussions] = useState(true)

    const [filter, setFilter] = useState(false)

    const [users, setUsers] = useState([])
    const [myDiscussions, setMyDiscussions] = useState([])

    const [show_not, setShow_Not] = useState(false)

    const [error, setError] = useState('')

    const navigate = useNavigate();

    const context = useContext(Context)

    useEffect(() => {

        document.title = "Mon espace membre - " + APP_NAME

        User.IfPremium(navigate)

        User.getUsersHome(setUsers, setLoadingUsers)

        getMyDiscussions(setMyDiscussions, setLoadingDiscussions)

    }, [])


    return (
        <div>

            <Header />

            {

                    <div className="row container_box col-10 my-3 mx-auto border border-1 rounded-1 bg-white">

                        <div className="col-4 border-end border-1 p-2">
                            <h2>Mes messages</h2>

                            <div className="p-3">

                                {

                                    loadingDiscussions

                                        ?

                                        <div className="d-flex justify-content-center my-5 py-xl-5">
                                            <div className="spinner-border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>

                                        :

                                        myDiscussions.map(element => <Card user={element} key={'Card_' + element.idU} />)

                                }

                            </div>

                        </div>

                        <div className="col-8 ov_h_s h_100 border-end border-1 p-2">

                            <div className="row align-items-center">

                                <h4 className="fs-4 col-6">Rechercher / Trier</h4>

                                <div className="col-5">

                                    <div className="input-group">

                                        <input id="search-focus" type="text" id="search_input" className="form-control col-7" placeholder="Recherche par mot" onKeyUp={() => search(setUsers, setLoadingUsers)} />

                                        <button type="button" className="btn btn-primary">Recherche</button>

                                    </div>

                                </div>

                                <div className="col-1">
                                    <i className="bi bi-filter-circle-fill btn btn-primary" title="Afficher les filtres" onClick={() => setFilter(!filter)}></i>
                                </div>

                            </div>

                            {filter &&

                                <form className="border border-1 rounded-1 p-2 my-3 bg-light" method="POST" onSubmit={(e) => filterFunc(e, setUsers, setLoadingUsers)}>

                                    <h3 className="fs-5">Filtre</h3>

                                    <div className="row">

                                        <div className="mb-lg-3 col-6">
                                            <label className="mb-2">Age de</label>
                                            <input type="number" className="form-control" name="age_min" placeholder="Age min..." />
                                        </div>

                                        <div className="mb-lg-3 col-6">
                                            <label className="mb-2">à</label>
                                            <input type="number" className="form-control" name="age_max" placeholder="Age max..." />
                                        </div>

                                    </div>

                                    <div className="row">

                                        <div className="mb-lg-3 col-3">
                                            <label className="mb-2">Poids de</label>
                                            <input type="number" className="form-control" name="poid_min" placeholder="Poids min..." />
                                        </div>

                                        <div className="mb-lg-3 col-3">
                                            <label className="mb-2">à</label>
                                            <input type="number" className="form-control" name="poid_max" placeholder="Poids max..." />
                                        </div>

                                        <div className="mb-lg-3 col-3">
                                            <label className="mb-2">Taille de</label>
                                            <input type="number" className="form-control" name="taille_min" placeholder="Taille min ..." />
                                        </div>

                                        <div className="mb-lg-3 col-3">
                                            <label className="mb-2">à</label>
                                            <input type="number" className="form-control" name="taille_max" placeholder="Taille max ..." />
                                        </div>

                                    </div>


                                    {

                                        show_not

                                        &&

                                        <div className="row mx-2">

                                            <div className="form-check col-3">
                                                <input className="form-check-input" type="checkbox" id="travail" />
                                                <label className="form-check-label" htmlFor="travail">Travail</label>
                                            </div>

                                            <div className="form-check col-3">
                                                <input className="form-check-input" type="checkbox" id="enfants" />
                                                <label className="form-check-label" htmlFor="enfants">Enfants à charge</label>
                                            </div>

                                            <div className="form-check col-3">
                                                <input className="form-check-input" type="checkbox" id="sounnah" />
                                                <label className="form-check-label" htmlFor="sounnah">Port de la sounnah</label>
                                            </div>

                                            <div className="form-check col-3">
                                                <input className="form-check-input" type="checkbox" id="hijra" />
                                                <label className="form-check-label" htmlFor="hijra">Hijra</label>
                                            </div>

                                        </div>

                                    }

                                    <button className="btn btn-primary mt-3" role="button"><i className="bi bi-search"></i> Trouver des profiles</button>

                                </form>

                            }

                            <div className="row row-cols-auto bg-white justify-content-between my-3 mx-1" id="results">

                                {

                                    loadingUsers

                                        ?

                                        <div className="d-flex justify-content-center my-5 py-xl-5">
                                            <div className="spinner-border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>

                                        :

                                        users

                                            ?

                                            users.map(element => <BigCard user={element} key={'BigCard_' + element.idU} />)

                                            :

                                            <div className="bg-light m-2 w-100 text-center p-3">Aucun résultat pour cette recherche</div>

                                }

                            </div>

                        </div>

                    </div>

            }

            <Footer />

        </div>
    )

}

export default Acceuil