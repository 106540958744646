import React, { useState, useEffect } from 'react';

import { Header, Footer } from '../components/imports'

import { APP_NAME } from '../constants/general'

function Page_404() {

    const [count, setCount] = useState(0);

    useEffect(() => {
        document.title = "Erreur 404 - Page introuvable"
    }, [])

    return (
        <div>

            <Header />

            <div className="min-vh-100 d-flex align-items-center">

                <div class=" col-5 mx-auto text-center py-5 my-5 shadow-lg rounded-2">

                    <h1 class="fs-1">404</h1>

                    <p class="fs-3"><span class="text-danger">Oops!</span> Page introuvable</p>

                    <p>La page que vous cherchez n'existe page.</p>

                    <a href="/">Retour vers l'accueil</a>

                </div>

            </div>

            <Footer />

        </div>
    )

}

export default Page_404