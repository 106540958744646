import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";

import User from '../class/User'

import { Header, Footer } from '../components/imports'

import { APP_NAME, AVATAR_ROOT, AVATAR_HOMME, AVATAR_FEMME } from '../constants/general'

const userGetInfo = async (setUser, id, navigate) => {

    let user = await User.userInfos(id)

    if(user.success){

        setUser(user.data)

        document.title = user.data.kunya + " " + user.data.prenom + " - " + APP_NAME

    }else{

        navigate('/404')

    }

}

function Profile() {

    let { id } = useParams()

    const [locading, setLocading] = useState(false)
    const [user, setUser] = useState([])

    const [userAllInfos, setUserAllInfos] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {

        User.IfPremium(navigate)

        document.title = "Utilisateur - " + APP_NAME

        userGetInfo(setUser, id, navigate)

        User.getAllUserInfos(setUserAllInfos, id)

    }, [])

    return (
        <div>

            <Header />

            <div className="">
                <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col col-lg-8 mb-4 mb-lg-0">
                            <Link to={'/acceuil'} className="btn mb-5">
                                <h3><i className="bi bi-arrow-left-square-fill text-primary"></i> Accueil</h3>
                            </Link>
                            
                            {

                                userAllInfos

                                ?

                                    <div className="card mb-3">

                                        <div className="row g-0">

                                            <div className="col-md-4 text-center text-white bg-primary rounded-2">

                                                {

                                                    userAllInfos.sexe == 1

                                                        ?

                                                        <img src={AVATAR_HOMME} alt="Avatar" className="img-fluid my-5 mx-auto col-10 rounded-circle"></img>

                                                        :

                                                        <img src={AVATAR_FEMME} alt="Avatar" className="img-fluid my-5 mx-auto col-10 rounded-circle"></img>

                                                }

                                                <h5>{userAllInfos.kunya} {userAllInfos.prenom}</h5>
                                                <p>{userAllInfos.pays} ({userAllInfos.ville})</p>
                                            </div>

                                            <div className="col-md-8">
                                                <div className="card-body p-4">
                                                    <h6 className="mb-3 fs-4">Informations</h6>
                                                    <div className="row pt-1">
                                                        <div className="col-6 mb-1">
                                                            <h6>Age</h6>
                                                            <p className="text-muted">{userAllInfos.age} ans</p>
                                                        </div>
                                                        <div className="col-6 mb-1">
                                                            <h6>Origine</h6>
                                                            <p className="text-muted">{userAllInfos.origine}</p>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 mb-1">
                                                        <h6>Nationalité</h6>
                                                        <p className="text-muted">{userAllInfos.nationalite}</p>
                                                    </div>

                                                    <div className="row pt-1">
                                                        <div className="col-6 mb-3">
                                                            <h6>Taille poids</h6>
                                                            <p className="text-muted">{userAllInfos.poid} Kg pour {userAllInfos.taille} Cm</p>
                                                        </div>
                                                        <div className="col-6 mb-3">
                                                            <h6>Travaill</h6>
                                                            <p className="text-muted">{userAllInfos.travail}</p>
                                                        </div>
                                                    </div>

                                                    <div className="row pt-1">
                                                        <div className="col-6 mb-3">
                                                            <h6>Enfants à charge</h6>
                                                            <p className="text-muted">
                                                                {
                                                                
                                                                userAllInfos.enfants == 0

                                                                ?

                                                                'Aucun'

                                                                :

                                                                userAllInfos.enfants
                                                                
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="row pt-1">
                                                        <div className="col-6 mb-3">
                                                            <h6>Port de la sounnah</h6>
                                                            <p className="text-muted">{userAllInfos.sounnah}</p>
                                                        </div>
                                                        <div className="col-6 mb-3">
                                                            <h6>Hijra</h6>
                                                            <p className="text-muted">{userAllInfos.hijra}</p>
                                                        </div>
                                                    </div>

                                                    <div className="row pt-1">
                                                        <div className="col-6 mb-3">
                                                            <h6>Pratique de la religion</h6>
                                                            <p className="text-muted">{userAllInfos.pratique}</p>
                                                        </div>
                                                        <div className="col-6 mb-3">
                                                            <h6>Polygamie</h6>
                                                            <p className="text-muted">{userAllInfos.polygamie}</p>
                                                        </div>
                                                    </div>

                                                    <div className="row pt-1">
                                                        <div className="col-6 mb-3">
                                                            <h6>Ce que je recherche</h6>
                                                            <p className="text-muted">{userAllInfos.chercher}</p>
                                                        </div>
                                                        <div className="col-6 mb-3">
                                                            <h6>Critères rédhibitoires</h6>
                                                            <p className="text-muted">{userAllInfos.criters}</p>
                                                        </div>
                                                    </div>

                                                    <div className="row pt-1">
                                                        <div className="col-6 mb-3">
                                                            <h6>Personnalité</h6>
                                                            <p className="text-muted">{userAllInfos.personalite}</p>
                                                        </div>
                                                        <div className="col-6 mb-3">
                                                            <h6>Hobbies</h6>
                                                            <p className="text-muted">{userAllInfos.hobbies}</p>
                                                        </div>
                                                    </div>

                                                    <p className="text-muted">* Je m’engage devant ALLAH AZAWDJEL a ne pas divulguer les profils consulter sur le site Et à ne pas communiquer de coordonnée lieux et téléphoniques quelconque</p>
                                                    <p className="text-muted">* Tous les messages envoyés sont validé avant que la personne les reçoits</p>

                                                    <div className="d-flex justify-content-start">
                                                        <Link to={'/discussion/' + userAllInfos.idU} className="btn btn-primary"> <i className='bi bi-chat-fill me-2'></i>Envoyer un message</Link>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                :

                                    <div className="d-flex justify-content-center my-5 py-xl-5">
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>

                            }

                            </div>

                        </div>
                    </div>

                </div>

            <Footer />

        </div>
    )

}

export default Profile