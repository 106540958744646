import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, Link, useSearchParams  } from 'react-router-dom';

import User from '../class/User'

import { Header, Footer } from '../components/imports'

import { APP_NAME, AVATAR_ROOT } from '../constants/general'

import { Context } from '../class/Context'

import { WindowScrollTop } from '../class/Func'

const handleSubmit = async (e, setLoading2, setError, setMessage) => {

    e.preventDefault()

    const input = e.target

    if (input.nom.value == '' || input.prenom.value == '' || input.age.value == '' || input.origine.value == '') {

        setError("Veuillez entrer toutes les informations demandées")

        WindowScrollTop()

        return false

    }

    setLoading2(true)

    const results = await User.editUser(input);

    setLoading2(false)

    if (results.success) {

        console.log(results)

        setMessage("Informations enregistées avec succès")

        WindowScrollTop()

    } else {

        setError(results.message)

        WindowScrollTop()

        return false

    }

}

const editPassword = async (e, setLoading2, setError, setMessage) => {

    e.preventDefault()

    const input = e.target

    if (input.last_password.value == '' || input.password.value == '' || input.password_verif.value == '') {

        setError("Veuillez entrer votre ancien mot de passe et le nouveau")

        WindowScrollTop()

        return false

    }

    setLoading2(true)

    const results = await User.editPassword(input);

    setLoading2(false)

    if (results.success) {

        setMessage(results.message)

        input.last_password.value = ''
        input.password.value = ''
        input.password_verif.value = ''

        WindowScrollTop()

    } else {

        setError(results.message)

        WindowScrollTop()

        return false

    }

}

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [day, month, year].join('/');
}

const profileImgLoad = async () => {

    let t = document.querySelector('#photo')

    let file = t.files[0]

    if(file.size == 0 || file.name == null) return

    let image_name = await User.avatar(file)

    console.log(image_name)

    if (image_name !== 'error') document.querySelector('#image_id').src = AVATAR_ROOT + image_name

}

const clickFileInput = (t) => {

    document.querySelector('#photo').click()

}

const userGetInfo = async (setUser, id, navigate) => {

    let user = await User.userInfos(id)

    console.log(user);

    if (user.success) {

        setUser(user.data)

        document.title = user.data.nom + " " + user.data.prenom + " - " + APP_NAME

    } else {

        navigate('/404')

    }

}

function Parametres() {

    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)

    const [stripeInfos, setStripeInfos] = useState(false)
    const [myInfos, setMyInfos] = useState(false)

    const [userAllInfos, setUserAllInfos] = useState(false)

    const [me, setMe] = useState(false)

    const [error, setError] = useState('')
    const [message, setMessage] = useState('')

    const navigate = useNavigate();

    const context = useContext(Context)
    
    const [searchParams, setSearchParams] = useSearchParams()
    const abonnement_variable = searchParams.get("pay")
    const register_variable = searchParams.get("register")

    useEffect(() => {

        User.IfPremium(navigate)

        User.getStripePortal(setStripeInfos)

        User.getAllUserInfos(setUserAllInfos)

        setMe(context.me)

        document.title = "Paramètres - " + APP_NAME

    }, [])


    return (
        <div>

            <Header />

            <div>
                <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col col-lg-8 mb-4 mb-lg-0">
                            <div className="card mb-3">
                                <div>
                                    <div className="">
                                        <div className="card-body p-4">
                                            <h6 className="mb-3 fs-4">Mon abonnement sur Salafi Zawaj</h6>

                                            <div className="mb-5">

                                                {

                                                    abonnement_variable

                                                    &&

                                                    <p className='alert alert-success'>Félicitations, vous venez d'activer votre abonnement sur Zawaj Muslims, commencez dès à présent à contacter d'autres utilisateurs et recevez des messages de leurs parts.</p>

                                                }

                                                {

                                                    register_variable

                                                    &&

                                                    <p className='alert alert-success'>Félicitations, vous avez finalisé votre inscription sur Zawaj Muslims, il ne vous reste que de payer votre abonnement pour commencer à utiliser nos services.</p>

                                                }

                                                {

                                                    stripeInfos

                                                    ?

                                                        stripeInfos.abonnement_stt

                                                        ?

                                                            stripeInfos.sexe == "1"

                                                            ?

                                                            <div>

                                                                <p className='alert alert-info'>Vous êtes abonné sur { APP_NAME }, Votre abonnement expire le <strong>{formatDate(stripeInfos.abonnement)}</strong>.</p>

                                                                <a href={stripeInfos.portal_url} className="btn btn-primary btn-sm">Gérer mon abonnement</a>

                                                            </div>

                                                            :

                                                            <div>

                                                                <p className='alert alert-info'>{ APP_NAME } est totalement gratuit pour les femmes.</p>

                                                            </div>

                                                        :

                                                            <div>

                                                                <p className='alert alert-danger'>Vous n'êtes pas abonné sur Salazi Zawaj, Abonnez vous pour pouvoir utiliser la messagerie et contactez d'autres utilisateurs</p>

                                                                <a href={"https://buy.stripe.com/eVag2wbpH3WWal26op?prefilled_email=" + userAllInfos.email + "&client_reference_id=" + userAllInfos.idU} className="btn btn-primary btn-sm">Payer un abonnement (2.99€ par mois)</a>

                                                            </div>
                                                    
                                                    :

                                                        <div className="d-flex justify-content-center my-5 py-xl-5">
                                                            <div className="spinner-border" role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </div>
                                                        </div>

                                                }

                                            </div>

                                            <h6 className="mb-3 fs-4">Modifier mes informations</h6>

                                            {
                                                
                                                userAllInfos
                                                
                                                ?

                                                    <div>

                                                    <form className="form-signin w-100 m-auto col-4" method="POST" onSubmit={(e) => handleSubmit(e, setLoading2, setError, setMessage)}>

                                                        {(error !== '') && <p className="alert alert-danger" role="alert">{error}</p>}

                                                        {(message !== '') && <p className="alert alert-success" role="alert">{message}</p>}

                                                        <div className='row'>

                                                            <div className="mb-lg-3 col-4">
                                                                <label className='mb-2'>Kunya</label>
                                                                <input type="text" className="form-control" name="kunya" placeholder="Abou, Oum, Ibn, Bint ..." required maxLength="16" defaultValue={userAllInfos.kunya}/>
                                                            </div>

                                                            <div className="mb-lg-3 col-4">
                                                                <label className='mb-2'>Mon nom</label>
                                                                <input type="text" className="form-control" name="nom" placeholder="Votre nom" required maxLength="128" defaultValue={userAllInfos.nom}/>
                                                            </div>

                                                            <div className="mb-lg-3 col-4">
                                                                <label className='mb-2'>Mon prénom</label>
                                                                <input type="text" className="form-control" name="prenom" id="prenom" placeholder="Votre prénom" required maxLength="128" defaultValue={userAllInfos.prenom}/>
                                                            </div>

                                                        </div>
                                                        
                                                        <div className='row'>

                                                            <div className="mb-lg-3 col-12">
                                                                <label className='mb-2'>Mon adresse email</label>
                                                                <input type="email" className="form-control" name="email" id="email" placeholder="Votre adresse email" required  defaultValue={userAllInfos.email}/>
                                                            </div>

                                                            <div className="mb-lg-3 col-6 d-none">
                                                                <label className='mb-2'>Mon numéro de téléphone</label>
                                                                <input type="text" className="form-control" name="telephone" id="telephone" placeholder="Votre numéro de téléphone" required  defaultValue={userAllInfos.telephone}/>
                                                            </div>

                                                        </div>

                                                        <div className='row'>

                                                            <div className="mb-lg-3 col-6">
                                                                <label className='mb-2'>Mon pays</label>
                                                                
                                                                <select name="pays" className="form-control" required defaultValue={userAllInfos.pays}>
                                                                        <option>France</option>
                                                                        <option>Algérie</option>
                                                                        <option>Maroc</option>
                                                                        <option>Tunisie</option>
                                                                        <option>Égypte</option>
                                                                        <option>Arabie Saoudite</option>
                                                                        <option>Émirats arabes unis</option>
                                                                        <option>Qatar</option>
                                                                        <option>Koweït</option>
                                                                        <option>Dubai</option>
                                                                        <option>Mali</option>
                                                                        <option>Congo</option>
                                                                        <option>Sénégale</option>
                                                                        <option>Côte d'ivoir</option>
                                                                        <option>Angleterre</option>
                                                                        <option>Espagne</option>
                                                                        <option>Italie</option>
                                                                        <option>Indonésie</option>
                                                                        <option>États-Unis</option>
                                                                        <option>Canada</option>
                                                                </select>
                                                            </div>

                                                            <div className="mb-lg-3 col-6">
                                                                <label className='mb-2'>Ma ville</label>
                                                                <input type="text" className="form-control" name="ville" id="ville" placeholder="Votre ville" required defaultValue={userAllInfos.ville}/>
                                                            </div>

                                                            <div className="mb-lg-3 col-4 d-none">
                                                                <label className='mb-2'>Mon code postal</label>
                                                                <input type="text" className="form-control" name="postal" id="postal" placeholder="Votre code postal" required defaultValue={userAllInfos.postal}/>
                                                            </div>

                                                        </div>

                                                        <p className='text-muted fs-6'>* L'identité sera confidentielle et ne sera pas visible sur les profils</p>

                                                        <hr className="my-4"/>

                                                        <div className='row'>

                                                            <div className="mb-lg-3 col-6">
                                                                <label className='mb-2'>Mon âge</label>
                                                                    <input type="number" className="form-control" name="age" placeholder="Age" required defaultValue={userAllInfos.age} min="18"/>
                                                            </div>

                                                            <div className="mb-lg-3 col-6">
                                                                <label className='mb-2'>Mon origine</label>
                                                                    <input type="text" className="form-control" name="origine" placeholder="Français" required defaultValue={userAllInfos.origine}/>
                                                            </div>

                                                        </div>

                                                        <div className="mb-lg-3 col-12">
                                                            <label className='mb-2'>Ma nationalité</label>
                                                            <input type="text" className="form-control" name="nationalite" placeholder="Français ..." required maxLength="256" defaultValue={userAllInfos.nationalite}/>
                                                        </div>

                                                        <div className='row'>

                                                            <div className="mb-lg-3 col-6">
                                                                <label className='mb-2'>Ma taille (cm)</label>
                                                                <input type="number" className="form-control" name="taille" placeholder="Ma taille" required defaultValue={userAllInfos.taille} min="100"/>
                                                            </div>

                                                            <div className="mb-lg-3 col-6">
                                                                <label className='mb-2'>Mon poids (kg)</label>
                                                                <input type="number" className="form-control" name="poid" placeholder="Mon poid" required defaultValue={userAllInfos.poid} min="40"/>
                                                            </div>

                                                        </div>

                                                        <div className='row'>

                                                            <div className="mb-lg-3 col-6">
                                                                <label className='mb-2'>Enfants à charge</label>

                                                                <select name="enfants" className="form-control" required defaultValue={userAllInfos.enfants}>
                                                                    <option value='0'>Aucun</option>
                                                                    <option>1</option>
                                                                    <option>2</option>
                                                                    <option>3</option>
                                                                    <option>4</option>
                                                                    <option>5</option>
                                                                    <option>5</option>
                                                                </select>
                                                            </div>

                                                            <div className="mb-lg-3 col-6">
                                                                <label className='mb-2'>Travail</label>
                                                                <input type="text" className="form-control" name="travail" placeholder="Travail ..." required maxLength="256" defaultValue={userAllInfos.travail}/>
                                                            </div>

                                                        </div>

                                                        <div className='row'>

                                                            <div className="mb-lg-3 col-6">
                                                                <label className='mb-2'>Port de la sounnah</label>
                                                                <input type="text" className="form-control" name="sounnah" maxLength="256" placeholder="Barbe, Qamis, Jilbeb, Hijab ..." defaultValue={userAllInfos.sounnah}/>
                                                            </div>

                                                            <div className="mb-lg-3 col-6">
                                                                <label className='mb-2'>Hijra</label>
                                                                <input type="text" className="form-control" name="hijra" placeholder="Hijra ..." required maxLength="256" defaultValue={userAllInfos.hijra}/>
                                                            </div>

                                                        </div>

                                                        <div className='row'>

                                                            <div className="mb-lg-3 col-6">
                                                                <label className='mb-2'>Pratique de la religion</label>
                                                                <input type="text" className="form-control" name="pratique" placeholder="5 prière à la mosquée ..." required maxLength="256" defaultValue={userAllInfos.pratique}/>
                                                            </div>

                                                            <div className="mb-lg-3 col-6">
                                                                <label className='mb-2'>Polygamie</label>
                                                                <input type="text" className="form-control" name="polygamie" placeholder="Polygamie ..." required maxLength="256" defaultValue={userAllInfos.polygamie}/>
                                                            </div>

                                                        </div>

                                                        <div className='row'>

                                                            <div className="mb-lg-3 col-6">
                                                                <label className='mb-2'>Ce que je recherche</label>
                                                                <input type="text" className="form-control" name="chercher" placeholder="Ce que je recherche ..." required maxLength="256" defaultValue={userAllInfos.chercher}/>
                                                            </div>

                                                            <div className="mb-lg-3 col-6">
                                                                <label className='mb-2'>Critères rédhibitoires</label>
                                                                <input type="text" className="form-control" name="criters" placeholder="Critères rédhibitoires ..." required maxLength="256" defaultValue={userAllInfos.criters}/>
                                                            </div>

                                                        </div>

                                                        <div className='row'>

                                                            <div className="mb-lg-3 col-6">
                                                                <label className='mb-2'>Ma personnalité</label>
                                                                <input type="text" className="form-control" name="personnalite" placeholder="Très actif au quotidien ..." required maxLength="256" defaultValue={userAllInfos.personalite}/>
                                                            </div>

                                                            <div className="mb-lg-3 col-6">
                                                                <label className='mb-2'>Mes hobbies</label>
                                                                <input type="text" className="form-control" name="hobbies" placeholder="Sport, Lecture ..." required maxLength="256" defaultValue={userAllInfos.hobbies}/>
                                                            </div>

                                                        </div>

                                                        <p className='text-muted fs-6'>* Toutes ces informations peuvent être visualisées sur votre profile.</p>
                                                        
                                                        <button className={"btn btn-primary mb-lg-5" + (loading2 ? " disabled" : "")} type="submit">{(loading2) && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}Enregistrer</button>

                                                    </form>

                                                    <h6 className="mb-3 fs-4">Modifier mon mot de passe</h6>

                                                    <form className="form-signin w-100 m-auto col-4" method="POST" onSubmit={(e) => editPassword(e, setLoading2, setError, setMessage)}>

                                                        <div className="mb-lg-3 col-12">
                                                            <label className='mb-2'>Ancien mot de passe</label>
                                                            <input type="password" className="form-control" name="last_password" placeholder="**********" required />
                                                        </div>

                                                        <div className='row'>

                                                            <div className="mb-lg-3 col-6">
                                                                <label className='mb-2'>Nouveau mot de passe</label>
                                                                <input type="password" className="form-control" name="password" placeholder="*********" required />
                                                            </div>

                                                            <div className="mb-lg-3 col-6">
                                                                <label className='mb-2'>Retappez le mot de passe</label>
                                                                <input type="password" className="form-control" name="password_verif" placeholder="*********" required />
                                                            </div>

                                                        </div>

                                                        <button className={"btn btn-primary" + (loading2 ? " disabled" : "")} type="submit">{(loading2) && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}Modifier mon mot de passe</button>

                                                    </form>

                                                    <h6 className="mb-3 fs-4 mt-5">Supprimer mon compte</h6>

                                                    <p>Vous voulez supprimer votre compte ?</p>

                                                    <Link to={'/supprimer-mon-compte'} className="btn btn-danger">Supprimer mon compte</Link>
{/*
                                                    <h6 className="mb-3 fs-4 mt-4">Modifier ma photo de profile</h6>

                                                    <div className="flex">

                                                        <div className="w-50">

                                                                {
                                                                    userAllInfos.avatar !== null

                                                                    ?

                                                                    <img src={AVATAR_ROOT + userAllInfos.avatar} alt="Avatar" className="img-fluid my-5 mx-auto col-7 rounded-circle" id="image_id"></img>

                                                                    :

                                                                    <img src={AVATAR_DEFAULT} alt="Avatar" className="img-fluid my-5 mx-auto col-7 rounded-circle" id="image_id"></img>

                                                                }

                                                            <input type="file" name="image" id="photo" className="d_none" onChange={() => profileImgLoad()} accept="image/png, image/gif, image/jpeg"/>
                                                            <span className="btn btn-primary mt-4" onClick={() => clickFileInput()}>Modifier la photo de mon profile</span>
                                                        </div>

                                                    </div>
                                                
*/}
                                                </div>
                                                
                                                :

                                                    <div className="d-flex justify-content-center my-5 py-xl-5">
                                                        <div className="spinner-border" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                    </div>
                                            }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            <Footer />

        </div>
    )

}

export default Parametres