import React, { useState, useEffect, useContext } from 'react';

import { Link, useNavigate, useParams  } from 'react-router-dom';

import User from '../class/User'
import Discussions from '../class/Discussions'

import { Header, Footer } from '../components/imports'
import { Card, Message } from '../components/Components'

import Moment from 'react-moment'
import 'moment/locale/fr';

import { APP_NAME } from '../constants/general'

import { Context } from '../class/Context'

const handleSubmit = (e, chat_id, setErrorMessage) => {

    e.preventDefault()

    const messages_box = document.querySelector('.messages_box')

    const input = document.querySelector('#input_send')

    if(input.value == '') return

    messages_box.innerHTML += `
        <div class="w-100 mb-3 d-flex justify-content-end align-items-center" >

            <p class="bg-light text-black m-0 d-inline-block p-1 rounded border border-1 shadow">${input.value}</p>

            <i class="bi bi-hourglass-split mx-2 -secondary" title='Message en attente de validation'></i>

        </div>
    `

    scrollMessageBox()

    // Envoyer le message

    Discussions.send(input.value, chat_id, setErrorMessage)

    input.value = ''

}

const showModal = (modal_selector) => {

    let modal = document.querySelector(modal_selector)
    
    modal.style.display = 'block'
    modal.style.background = 'rgba(0,0,0,0.3)'

    modal.classList.add('show')

}

const hideModal = (modal_selector) => {

    let modal = document.querySelector(modal_selector)

    modal.style.display = 'none'

    modal.classList.remove('show')

}

const closeDiscussion = (e, discussion_id, setErrorMessage, navigate) => {

    Discussions.close(discussion_id, setErrorMessage, navigate)

}

const getDiscussion = async (id, setDiscussion, setUser, setMessages, scroll = false) => {

    let discussion = await Discussions.get(id)

    if (discussion.success) {

        setDiscussion(discussion.chat_id)

        setUser(discussion.user)

        setMessages(discussion.messages)

        document.title = discussion.user.nom + " " + discussion.user.prenom + " - " + APP_NAME

        if(scroll) setTimeout(scrollMessageBox, 50)

    } else {

        alert(discussion.message)

    }

}


const getMyDiscussions = async (setMyDiscussions, setLoadingDiscussions) => {

    let discussions = await Discussions.myDiscussions()

    if (discussions.success) {

        setLoadingDiscussions(false)

        setMyDiscussions(discussions.data)

    } else {

        //navigate('/404')

        alert(discussions.message)

    }

}

const scrollMessageBox = (px = false) => {

    let messages_box = document.querySelector('.messages_box')

    if(px){
        messages_box.scrollTop = px
    }else{
        messages_box.scrollTop = messages_box.scrollHeight
    }
    
}

let limit_messages = 20

const loadMoreMessages = async (discussion, setMessages) => {

    limit_messages = limit_messages + 20

    let messages = await Discussions.getMessages(discussion, limit_messages)

    if (messages.success) {

        setMessages(messages.messages)

        scrollMessageBox(60)

    }

}

function Discussion() {

    const { id } = useParams()

    const [errorMessage, setErrorMessage] = useState(false)

    const [loading, setLoading] = useState(false)
    const [loadingDiscussions, setLoadingDiscussions] = useState(true)

    const [discussion, setDiscussion] = useState(false)
    const [messages, setMessages] = useState([])

    const [user, setUser] = useState({nom: 'Utilisateur', prenom: ''})
    const [myDiscussions, setMyDiscussions] = useState([])

    const navigate = useNavigate()

    const context = useContext(Context)
    
    useEffect(() => {
        
        //User.getInfos(setLoading, navigate, context)
        
        getDiscussion(id, setDiscussion, setUser, setMessages, true)

        setInterval(function(){

            getDiscussion(id, setDiscussion, setUser, setMessages, false)

        }, 20000)

        document.title = user.nom + ' ' + user.prenom + " - " + APP_NAME

        getMyDiscussions(setMyDiscussions, setLoadingDiscussions)

    }, [])


    return (
        <div>

            <Header />

            <div className="row container_box col-10 my-3 mx-auto border border-1 rounded-1 bg-white">

                <div className="col-4 border-end border-1 p-2 d_none_m">
                    <h2>Mes messages</h2>

                    <div className="p-3">

                        {

                            loadingDiscussions

                            ?

                            <div className="d-flex justify-content-center my-5 py-xl-5">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>

                            :

                            myDiscussions.map(element => <Card user={element} key={'Card_' + element.idU}/>)

                        }

                    </div>

                </div>

                <div className="col-8 border-end border-1">
                    <div className="row d_flex justify-content-between border-bottom p-2 align-items-center">
                        <h2 className="w-50 fs-4 mb-0">{user.nom} {user.prenom}</h2>

                        <div className="btn-group w-50">
                            <Link to={'/profile/' + user.idU} className="col-8 btn-sm btn btn-primary"><i className="bi bi-person-fill"></i> Profile</Link>
                            
                            {
                                true
                                
                                &&
                                
                                <button className="col-3 btn-sm btn btn-danger" title="Clôturer une conversation" data-toggle="modal" data-target="#blockModal" onClick={() => showModal('#blockModal')}><i class="bi bi-trash-fill"></i></button>

                            }

                        </div>
                    </div>

                    <div className="messages_box_container">
                        
                        <div className="p-2 messages_box" id="messages_box">

                            {

                                !discussion

                                    ?

                                    <div className="d-flex justify-content-center my-5 py-xl-5">
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>

                                    :

                                    messages.length !== 0

                                    ?

                                    <div>

                                        <div className='btn bg-light m-2 w-100' onClick={() => loadMoreMessages(discussion, setMessages)}>Afficher plus de messages</div>

                                        { messages.map(element => <Message message={element} key={'Message_' + element.i} />) }
                                    
                                    </div>

                                    :

                                    <p className="bg-light m-2 w-100 p-3 text-center">Aucun message pour l'instant, Vous pouvez envoyer des messages.</p>

                            }

                        </div>

                    </div>

                    <form method="POST" className="input-group mb-3 input_messages_box" onSubmit={(e) => handleSubmit(e, discussion, setErrorMessage)} autoComplete="off">
                        <input type="text" placeholder="Votre message ..." className="form-control" id="input_send"></input>
                        <button className="input-group-text bg-primary text-white">Envoyer</button>
                    </form>

                </div>

            </div>

            <div className="modal fade" id="blockModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Clôturer cette conversation</h5>
                        </div>
                        <div className="modal-body">
                            Voullez vous vraiment clôturer cette conversation avec <strong>{user.nom} {user.prenom}</strong> ?

                           <p className="alert alert-info mt-3 mb-2">Si vous clôturez cette conversation ni vous ni {user.nom} {user.prenom} ne pourriez la renvoyer.</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={(e) => closeDiscussion(e, discussion, setErrorMessage, navigate)}>Clôturer</button>
                            <button type="button" className="btn btn-secondary" onClick={() => hideModal('#blockModal')}>Annuler</button>
                        </div>
                    </div>
                </div>
            </div>

            {

                errorMessage

                &&

                <div className="modal fade show" style={{ display: 'block', background: 'rgba(0, 0, 0, 0.3)'}} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Impossible d'envoyer un message</h5>
                            </div>
                            <div className="modal-body">
                                <p className="alert alert-danger mt-3 mb-2">{errorMessage}</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setErrorMessage(false)}>Fermer</button>
                            </div>
                        </div>
                    </div>
                </div>


            }

            <Footer />

        </div>
    )

}

export default Discussion