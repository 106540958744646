import React, { useState, useEffect, useContext } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import { Context } from '../class/Context'

import { Header, Footer } from '../components/imports'

import User from '../class/User'

import { WindowScrollTop } from '../class/Func'

import { APP_NAME } from '../constants/general'

const handleSubmit = async (e, setLoading, setError, navigate, setSession) => {

    e.preventDefault()

    const input = e.target

    setLoading(true)

    const results = await User.login(input)

    setLoading(false)

    if (results.success) {

        localStorage.setItem('id', results.data.idU);
        localStorage.setItem('app_token', results.data.app_token);
        localStorage.setItem('nom', results.data.nom);
        localStorage.setItem('prenom', results.data.prenom);
        localStorage.setItem('avatar', results.data.avatar);
        localStorage.setItem('sexe', results.data.sexe);

        setSession(true)

        // Redirect to espace membre

        if(results.redirect == 'accueil'){
            navigate('/acceuil')
        }else{
            navigate('/bienvenue')
        }

    } else {

        setError(results.message)

        WindowScrollTop()

        return false

    }

}

function Connexion() {

    const [loading, setLoading] = useState(false)

    const [error, setError] = useState('')

    const navigate = useNavigate()

    const context = useContext(Context)

    useEffect(() => {
        document.title = "Connexion - " + APP_NAME
    }, [])

    return (
        <div>

            <Header />

            <main className="m-auto container_box col-3 my-xl-5">
                <form className="form-signin w-100 m-auto col-4 p-4" method="POST" onSubmit={(e) => handleSubmit(e, setLoading, setError, navigate, context.setSession)}>
                    <h1 className="h3 mb-3 fw-normal text-center mb-md-5">Connectez vous à votre compte - { APP_NAME }</h1>

                    {(error !== '') && <p class="alert alert-danger" role="alert">{error}</p>}

                    <div className="form-floating mb-lg-3">
                        <input type="email" name="email" className="form-control" id="floatingInput" placeholder="name@example.com" required/>
                        <label htmlFor="floatingInput">Numéro de téléphone / Email</label>
                    </div>

                    <div className="form-floating mb-5">
                        <input type="password" name="password" className="form-control" id="floatingPassword" placeholder="Password" required/>
                        <label htmlFor="floatingPassword">Mot de passe</label>
                    </div>

                    <div className='d-flex flex-wrap justify-content-between align-items-center'>

                        <button className={"btn btn-primary" + (loading ? " disabled" : "")} type="submit">{(loading) && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}Connexion</button>

                        <Link to="/motdepasse" className="text-danger text-decoration-none">Mot de passe oublié !</Link>

                    </div>
                    
                </form>
            </main>

            <Footer />

        </div>
    )

}

export default Connexion