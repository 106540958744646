import React, { useState, useEffect, useContext } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import { Context } from '../class/Context'

import { Header, Footer } from '../components/imports'

import User from '../class/User'

import { WindowScrollTop } from '../class/Func'

import { APP_NAME } from '../constants/general'

const sendCode = async (e, setLoading, setError, setSuccess, setEtape, setEmail) => {

    e.preventDefault()

    const input = e.target

    setLoading(true)

    const results = await User.sendCodePassword(input)

    setLoading(false)

    if (results.success) {

        setEtape(2)

        setSuccess(results.message)

        setError('')

        setEmail(results.email)

    } else {

        setError(results.message)

        WindowScrollTop()

        return false

    }

}

const resetPassword = async (e, setLoading, setError, setSuccess, setEtape) => {

    e.preventDefault()

    const input = e.target

    setLoading(true)

    const results = await User.resetPassword(input)

    setLoading(false)

    if (results.success) {

        setEtape(1)

        setSuccess(results.message)

        setError('')

    } else {

        setError(results.message)

        WindowScrollTop()

        return false

    }

}

function Motdepasse() {

    const [loading, setLoading] = useState(false)

    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')

    const [email, setEmail] = useState('')

    const [stape, setEtape] = useState(1)

    useEffect(() => {
        document.title = "Réinitialiser mon mot de passe - " + APP_NAME
    }, [])

    return (
        <div>

            <Header />

            <main className="m-auto container_box col-3 my-xl-5">
                
                {

                    stape == 1

                    ?


                        <form className="form-signin w-100 m-auto col-4 p-4" method="POST" onSubmit={(e) => sendCode(e, setLoading, setError, setSuccess, setEtape, setEmail)}>
                            <h1 className="h3 mb-3 fw-normal text-center mb-md-5">Réinitialiser mon mot de passe - {APP_NAME}</h1>

                            <p>Entrez votre adresse pour recevoir un email de réinitialisation de mot de passe</p>

                            {(error !== '') && <p className="alert alert-danger" role="alert">{error}</p>}
                            {(success !== '') && <p className="alert alert-success" role="alert">{success}</p>}

                            <div className="form-floating mb-lg-3">
                                <input type="email" name="email" className="form-control" id="floatingInput" placeholder="name@example.com" required />
                                <label htmlFor="floatingInput">Votre adresse email</label>
                            </div>

                            <button className={"btn btn-primary" + (loading ? " disabled" : "")} type="submit">{(loading) && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}Envoyer un code</button>

                        </form>

                    :

                        <form className="form-signin w-100 m-auto col-4 p-4" method="POST" onSubmit={(e) => resetPassword(e, setLoading, setError, setSuccess, setEtape)} autoComplete="off">
                            <h1 className="h3 mb-3 fw-normal text-center mb-md-5">Réinitialiser mon mot de passe - {APP_NAME}</h1>

                            <p>Entrez le code que vous avez reçu par email</p>

                            {(error !== '') && <p className="alert alert-danger" role="alert">{error}</p>}
                            {(success !== '') && <p className="alert alert-success" role="alert">{success}</p>}

                            <div className="form-floating mb-lg-3">
                                <input type="email" name="email" className="form-control" id="floatingInput" placeholder="name@example.com" required defaultValue={email} autoComplete="off"/>
                                <label htmlFor="floatingInput">Votre adresse email</label>
                            </div>

                            <div className="form-floating mb-lg-3">
                                <input type="text" name="code" className="form-control" id="floatingInput" placeholder="Code reçu par email ..." required />
                                <label htmlFor="floatingInput">Votre code</label>
                            </div>

                            <hr />

                            <h4 className="fs-5 mb-4"><i className="bi bi-unlock-fill"></i> Nouveau mot de passe</h4>

                            <div className="form-floating mb-lg-3">
                                <input type="password" name="password" className="form-control" id="floatingInput" placeholder="*********" required />
                                <label htmlFor="floatingInput">Nouveau mot de passe</label>
                            </div>

                            <div className="form-floating mb-lg-3">
                                <input type="password" name="password_verif" className="form-control" id="floatingInput" placeholder="*********" required />
                                <label htmlFor="floatingInput">Retappez le mot de passe</label>
                            </div>

                            <button className={"btn btn-primary" + (loading ? " disabled" : "")} type="submit">{(loading) && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}Réinitialiser</button>

                        </form>


                }
                

            </main>

            <Footer />

        </div>
    )

}

export default Motdepasse