import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import User from '../class/User'

import { Header, Footer } from '../components/imports'

import { APP_NAME } from '../constants/general'

import { Context } from '../class/Context'

import { WindowScrollTop } from '../class/Func'

const handleSubmit = async (e, setLoading2, setError, navigate) => {

    e.preventDefault()

    const input = e.target

    if (input.age.value == '' || input.origine.value == '') {

        setError("Veuillez entrer toutes les informations demandées")

        WindowScrollTop()

        return false

    }

    setLoading2(true)

    const results = await User.editInfos(input);

    setLoading2(false)

    if (results.success) {

        navigate('/parametres?register=true')

    } else {

        setError(results.message)

        WindowScrollTop()

        return false

    }

}

function Bienvenue() {

    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)

    const [myInfos, setMyInfos] = useState(false)

    const [sexe, setSexe] = useState(1)

    const [error, setError] = useState('')

    const navigate = useNavigate()

    const context = useContext(Context)

    const [searchParams, setSearchParams] = useSearchParams()
    const abonnement_variable = searchParams.get("pay")
    const register_variable = searchParams.get("register")

    useEffect(() => {

        User.IfPremium(navigate)

        User.getInfos(setLoading, navigate, context, setMyInfos)

    }, [])


    return (
        <div>

            <Header />

            <div>
                <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col col-lg-8 mb-4 mb-lg-0">
                            <div className="card mb-3">
                                <div>
                                    <div className="">
                                        <div className="card-body p-4">
                                            <h6 className="mb-3 fs-4">Bienvenue {localStorage.getItem('nom') }</h6>

                                            {

                                                abonnement_variable

                                                &&

                                                <p className='alert alert-success'>Félicitations, vous venez d'activer votre abonnement sur Salafi Muslims, commencez dès à présent à contacter d'autres utilisateurs et recevez des messages de leurs parts.</p>

                                            }

                                            <p className="alert alert-success">Votre inscription sur <strong>{APP_NAME}</strong> c'est bien déroulée, Pour utiliser votre compte veuillez entrer plus d'informations pour remplir votre profile.</p>

                                            { 
                                                (loading) ?

                                                <div className="d-flex justify-content-center my-5 py-xl-5">
                                                    <div className="spinner-border" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                </div>

                                                :

                                                    <form className="form-signin w-100 m-auto col-4" method="POST" onSubmit={(e) => handleSubmit(e, setLoading2, setError, navigate)}>
                                                        <h1 className="h3 mb-3 fw-normal text-center mb-md-3 mb-md-3">Complétez votre profile</h1>

                                                        {(error !== '') && <p className="alert alert-danger" role="alert">{error}</p>}

                                                        <div className='row'>

                                                            <div className="mb-lg-3 col-6">
                                                                <label className='mb-2'>Mon age <span className='text-danger'>*</span></label>
                                                                <input type="number" className="form-control" name="age" placeholder="Age" required={true} min="18"/>
                                                            </div>

                                                            <div className="mb-lg-3 col-6">
                                                                <label className='mb-2'>Mon origine <span className='text-danger'>*</span></label>
                                                                <input type="text" className="form-control" name="origine" placeholder="Français" required={true} maxLength="128"/>
                                                            </div>

                                                        </div>

                                                        <div className="mb-lg-3 col-12">
                                                            <label className='mb-2'>Ma nationalité <span className='text-danger'>*</span></label>
                                                            <input type="text" className="form-control" name="nationalite" placeholder="Français ..." required={true} maxLength="256" />
                                                        </div>

                                                        <div className='row'>

                                                            <div className="mb-lg-3 col-6">
                                                                <label className='mb-2'>Ma taille (cm) <span className='text-danger'>*</span></label>
                                                                <input type="number" className="form-control" name="taille" placeholder="Ma taille" required={true} min="100"/>
                                                            </div>

                                                            <div className="mb-lg-3 col-6">
                                                                <label className='mb-2'>Mon poids (kg) <span className='text-danger'>*</span></label>
                                                                <input type="number" className="form-control" name="poid" placeholder="Mon poid" required={true} min="40"/>
                                                            </div>

                                                        </div>

                                                        <div className='row'>

                                                            <div className="mb-lg-3 col-6">
                                                                <label className='mb-2'>Enfants à charge <span className='text-danger'>*</span></label>

                                                                <select name="enfants" className="form-control" required={true}>
                                                                    <option value="0">Aucun</option>
                                                                    <option>1</option>
                                                                    <option>2</option>
                                                                    <option>3</option>
                                                                    <option>4</option>
                                                                    <option>5</option>
                                                                    <option>6</option>
                                                                </select>
                                                            </div>

                                                            <div className="mb-lg-3 col-6">
                                                                <label className='mb-2'>Travail <span className='text-danger'>*</span></label>
                                                                <input type="text" className="form-control" name="travail" placeholder="Travail ..." required={true} maxLength="256"/>
                                                            </div>

                                                        </div>

                                                        <div className='row'>

                                                            <div className="mb-lg-3 col-6">
                                                                <label className='mb-2'>Port de la Sounnah <span className='text-danger'>*</span></label>
                                                                <input type="text" className="form-control" name="sounnah" placeholder="Barbe, Qamis, Jilbeb, Hijab ..." required={true} maxLength="256"/>
                                                            </div>

                                                            <div className="mb-lg-3 col-6">
                                                                <label className='mb-2'>Hijra <span className='text-danger'>*</span></label>
                                                                <input type="text" className="form-control" name="hijra" placeholder="Hijra ..." required={true} maxLength="256"/>
                                                            </div>

                                                        </div>

                                                        <div className='row'>

                                                            <div className="mb-lg-3 col-6">
                                                                <label className='mb-2'>Pratique de la religion <span className='text-danger'>*</span></label>
                                                                <input type="text" className="form-control" name="pratique" placeholder="5 prière à la mosquée ..." required={true} maxLength="256"/>
                                                            </div>

                                                            <div className="mb-lg-3 col-6">
                                                                <label className='mb-2'>Polygamie <span className='text-danger'>*</span></label>
                                                                <input type="text" className="form-control" name="polygamie" placeholder="Polygamie ..." required={true} maxLength="256"/>
                                                            </div>

                                                        </div>

                                                        <div className='row'>

                                                            <div className="mb-lg-3 col-6">
                                                                <label className='mb-2'>Ce que je recherche <span className='text-danger'>*</span></label>
                                                                <input type="text" className="form-control" name="chercher" placeholder="Ce que je recherche ..." required={true} maxLength="256"/>
                                                            </div>

                                                            <div className="mb-lg-3 col-6">
                                                                <label className='mb-2'>Critères rédhibitoires <span className='text-danger'>*</span></label>
                                                                <input type="text" className="form-control" name="criters" placeholder="Critères rédhibitoires ..." required={true} maxLength="256"/>
                                                            </div>

                                                        </div>

                                                        <div className='row'>

                                                            <div className="mb-lg-3 col-6">
                                                                <label className='mb-2'>Ma personnalité <span className='text-danger'>*</span></label>
                                                                <input type="text" className="form-control" name="personnalite" placeholder="Très actif au quotidien ..." required={true} maxLength="256" />
                                                            </div>

                                                            <div className="mb-lg-3 col-6">
                                                                <label className='mb-2'>Mes hobbies <span className='text-danger'>*</span></label>
                                                                <input type="text" className="form-control" name="hobbies" placeholder="Sport, Lecture ..." required={true} maxLength="256" />
                                                            </div>

                                                        </div>

                                                        <p className='text-muted fs-6'>* Toutes ces informations peuvent être visualisées sur votre profile.</p>
                                                        
                                                        {

                                                            localStorage.getItem('sexe') == 1

                                                            &&

                                                            <p className='alert alert-info'>Une fois toutes les informations enregistrées vous allez être redérigé vers la page de paiement pour activer votre profile.</p>

                                                        }

                                                        <button className={"btn btn-primary" + (loading2 ? " disabled" : "")} type="submit">{(loading2) && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}Enregistrer</button>

                                                    </form>
                                                
                                            }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            <Footer />

        </div>
    )

}

export default Bienvenue