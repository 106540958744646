import axios from "axios";

import { API_ROOT, API_SECRET } from '../constants/general';

import { Logout } from '../class/Func'

class User {

    static register = (input) => {

        let data = fetch(API_ROOT + "register", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                kunya: input.kunya.value,
                nom: input.nom.value,
                prenom: input.prenom.value,
                sexe: input.sexe.value,
                email: input.email.value,
                telephone: input.telephone.value,
                pays: input.pays.value,
                ville: input.ville.value,
                postal: input.postal.value,
                password: input.password.value,
                password_2: input.password_2.value
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static login = (input) => {
        let data = fetch(API_ROOT + "login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                email: input.email.value,
                password: input.password.value,
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data
    }

    static session = () => {
        let data = fetch(API_ROOT + "session", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id: localStorage.getItem('id'),
                app_token: localStorage.getItem('app_token')
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data
    }

    static connected = async (setSession, setMe) => {

        if (localStorage.getItem('id') !== undefined && localStorage.getItem('app_token') !== undefined) {

            const results = await this.session()

            if (!results.success) {

                localStorage.removeItem('id')
                localStorage.removeItem('app_token')
                localStorage.removeItem('nom')
                localStorage.removeItem('prenom')
                localStorage.removeItem('avatar')
                localStorage.removeItem('sexe')

                setSession(false)

            } else {

                setSession(true)
                setMe(results.data)
                
            }

            return results.success

        } else {

            return false

        }

    }

    static supprimer = () => {
        let data = fetch(API_ROOT + "supprimer", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id: localStorage.getItem('id'),
                app_token: localStorage.getItem('app_token')
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data
    }

    static getInfos = async (setLoading, navigate, context, setMyInfos) => {

        setLoading(true)

        const results = await this.session()

        setMyInfos(results.data)

        setLoading(false)

        if (!results.success) Logout(navigate, context, '404')

    }

    static getAllUserInfos = async (setUserAllInfos, user = false) => {

        if (!user) user = localStorage.getItem('id')

        fetch(API_ROOT + "allUserInfos", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id: localStorage.getItem('id'),
                app_token: localStorage.getItem('app_token'),
                user_id: user,
                my_account: true
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            
            if (data.success) {
                setUserAllInfos(data.data)
            }

        })

    }

    static getStripePortal = async (setStripeInfos) => {
        
        fetch(API_ROOT + "stripeInfos", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id: localStorage.getItem('id'),
                app_token: localStorage.getItem('app_token')
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            
            if(data.success){
                setStripeInfos(data.data)
            }else{
                alert('Error');
            }

        })

    }

    static IfPremium = async (navigate) => {

        fetch(API_ROOT + "stripeInfos", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id: localStorage.getItem('id'),
                app_token: localStorage.getItem('app_token')
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            if (data.data.abonnement_stt == false) navigate('/abonnement')

        })

    }

    static userInfos = (id) => {

        let data = fetch(API_ROOT + "userInfos", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id: localStorage.getItem('id'),
                app_token: localStorage.getItem('app_token'),
                user_id: id
                
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static getUsersHome = (setUsers, setLoadingUsers, search_value = {}) => {
        let data = fetch(API_ROOT + "getUsersHome", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id: localStorage.getItem('id'),
                app_token: localStorage.getItem('app_token'),
                search_value: search_value
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            setLoadingUsers(false)
            setUsers(data.data)

            //data.data.map(i => console.log(i))

        })

        return data
    }

    static avatar = (file) => {

        var formdata = new FormData()
        formdata.append('image', file)
        formdata.append('id', localStorage.getItem('id'))
        formdata.append('app_token', localStorage.getItem('app_token'))
        
        let data = fetch(API_ROOT + "avatar", {
            method: "POST",
            body: formdata
        }).then(response => response.text())
        .then(data => {

            return data

        })

        return data
    }

    // Paramètres

    static editInfos = (input) => {

        let sounnah, hijra = ''

        if (input.sounnah !== undefined) sounnah = input.sounnah.value
        if (input.hijra !== undefined) hijra = input.hijra.value

        let data = fetch(API_ROOT + "editInfos", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id: localStorage.getItem('id'),
                app_token: localStorage.getItem('app_token'),
                age: input.age.value,
                origine: input.origine.value,
                nationalite: input.nationalite.value,
                taille: input.taille.value,
                poid: input.poid.value,
                enfants: input.enfants.value,
                travail: input.travail.value,
                personnalite: input.personnalite.value,
                pratique: input.pratique.value,
                polygamie: input.polygamie.value,
                chercher: input.chercher.value,
                criters: input.criters.value,
                hobbies: input.hobbies.value,
                sounnah: sounnah,
                hijra: hijra
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            console.log(data)

            return data

        })

        return data

    }

    static editUser = (input) => {

        let sounnah = ''
        let hijra = ''

        if (input.sounnah !== undefined) sounnah = input.sounnah.value
        if (input.hijra !== undefined) hijra = input.hijra.value

        let data = fetch(API_ROOT + "userEdit", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id: localStorage.getItem('id'),
                app_token: localStorage.getItem('app_token'),
                kunya: input.kunya.value,
                nom: input.nom.value,
                prenom: input.prenom.value,
                email: input.email.value,
                telephone: input.telephone.value,
                pays: input.pays.value,
                ville: input.ville.value,
                postal: input.postal.value,
                age: input.age.value,
                origine: input.origine.value,
                taille: input.taille.value,
                poid: input.poid.value,
                enfants: input.enfants.value,
                travail: input.travail.value,
                nationalite: input.nationalite.value,
                personnalite: input.personnalite.value,
                pratique: input.pratique.value,
                polygamie: input.polygamie.value,
                chercher: input.chercher.value,
                criters: input.criters.value,
                hobbies: input.hobbies.value,
                sounnah: sounnah,
                hijra: hijra
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static editPassword = (input) => {

        let data = fetch(API_ROOT + "userPassword", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id: localStorage.getItem('id'),
                app_token: localStorage.getItem('app_token'),
                last_password: input.last_password.value,
                password: input.password.value,
                password_verif: input.password_verif.value
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            //console.log(data)

            return data

        })

        return data

    }

    static sendCodePassword = (input) => {

        let data = fetch(API_ROOT + "userSendCodePassword", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                email: input.email.value
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static resetPassword = (input) => {

        let data = fetch(API_ROOT + "userResetPassword", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                email: input.email.value,
                code: input.code.value,
                password: input.password.value,
                password_verif: input.password_verif.value
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

}


export default User