import { WEBROOT, API_ROOT, API_SECRET } from '../constants/general';

class Discussion {

    static get = (id) => {

        let data = fetch(API_ROOT + "discussion", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id: localStorage.getItem('id'),
                app_token: localStorage.getItem('app_token'),
                user_id: id
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static getMessages = (discussion, limit) => {

        let data = fetch(API_ROOT + "getMessages", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id: localStorage.getItem('id'),
                app_token: localStorage.getItem('app_token'),
                chat_id: discussion,
                limit: limit
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            //console.log(limit)
            //console.log(data)
            return data

        })

        return data

    }

    static myDiscussions = () => {

        let data = fetch(API_ROOT + "myDiscussions", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id: localStorage.getItem('id'),
                app_token: localStorage.getItem('app_token')
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static send = (msg, chat_id, setErrorMessage) => {

        fetch(API_ROOT + "send", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id: localStorage.getItem('id'),
                app_token: localStorage.getItem('app_token'),
                chat_id: chat_id,
                msg: msg
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            
            //console.log(data.message)

            if (!data.success) setErrorMessage(data.message)

        })

    }

    static close = (discussion_id, setErrorMessage, navigate) => {

        fetch(API_ROOT + "closeDiscussion", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id: localStorage.getItem('id'),
                app_token: localStorage.getItem('app_token'),
                chat_id: discussion_id
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            
            console.log(data)

            if (!data.success){
                setErrorMessage(data.message)
            }else{
                alert(data.message)
                //navigate('../acceuil')

                window.location.href = WEBROOT + 'acceuil';
            }

        })

    }

}


export default Discussion