import React, { useState, useEffect, useContext } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import { Header, Footer } from '../components/imports'

import { WEBROOT, API_SECRET, API_ROOT } from '../constants/general';

function redirect(navigate){
    if (localStorage.getItem('app_token') !== null) navigate('/acceuil')
}

function getText(texts, id){
    if (texts[id - 1] !== undefined) return texts[id - 1].text
}

function getImage(images, id) {
    if (images[id - 1] !== undefined) return images[id - 1].image
}

function homeInfos(setTexte, setImages){

    fetch(API_ROOT + "home", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            api_secret: API_SECRET
        })
    }).then(function (response) {
        return response.json()
    }).then(function (data) {

        if (data.success) {

            console.log(data)

            setTexte(data.texts)
            setImages(data.images)

        } else {
            alert('Error');
        }

    })

}

function Home(){

    const [texts, setTexts] = useState([])
    const [images, setImages] = useState([])

    const navigate = useNavigate()
    
    useEffect(() => {
        
        document.title = "Zawaj Muslims, Votre site de rencontre Halal"

        homeInfos(setTexts, setImages)

        redirect(navigate)

    }, [])

    return(

        <div>

                <Header />

                    <div className="hero">

                        <div className="hero__overlay"></div>

                        <img className="img-fluid hero__video" src={ getImage(images, 1) } />

                        <div className="hero__content h-100 container-custom position-relative">
                            <div className="d-flex h-100 align-items-center hero__content-width">
                                <div className="text-white">
                                    <h1 className="hero__heading fw-bold mb-4 " style={{ color: '#d4a242' }}>{ getText(texts, 1) }</h1>
                                    <p className="lead mb-4">{ getText(texts, 2) }</p>
                                    <Link to="/inscription" className="mt-2 btn btn-lg btn-outline-light" role="button">Commencer</Link>
                                </div>
                            </div>
                        </div>

                        <a href="#scroll-down" className="hero__scroll-btn">Explorez <i className="bi bi-arrow-down-short"></i></a>

                    </div>

                    <a id="scroll-down"></a>
                    
                    <div className="steps container-custom">

                        <div className="row">
                            <div className="col-12 col-sm-6 d-md-flex justify-content-md-ceneter">
                                <img src={ getImage(images, 2) } alt="Coffe Flavour" className="img-fluid pb-4 steps__section-thumbnail" width="553" height="746" loading="lazy" />
                            </div>

                            <div className="col-12 col-sm-6 align-self-center justify-content-md-ceneter">

                                <div className="steps__content-width">
                                    <h2 className="h2 mb-4">{getText(texts, 3)}</h2>
                                    <p className="mb-4"><a href={getText(texts, 4)} target='_blank'>Consulter l'article</a></p>
                                </div>

                            </div>
                        </div>

                    </div>
                    

                    <div className="steps steps--background bg--primary-color-highlight">

                        <div className="container-custom">
                            <div className="row">
                                <h2 className="hero__heading fw-bold mb-4  ">{ getText(texts, 5) }</h2>
                                <div className="col">
                                    <img src={ getImage(images, 3) } className="mb-4 img-fluid" alt="Essential Nutrients" width="50%" height="25%" loading="lazy" />
                                    <h5>{ getText(texts, 6) }</h5>
                                    <p>{ getText(texts, 7) }</p>
                                </div>
                                <div className="col">
                                    <img src={ getImage(images, 4) } className="mb-4 img-fluid" alt="Essential Nutrients" width="50%" height="25%" loading="lazy" />
                                    <h5>{ getText(texts, 8) }</h5>
                                    <p>{ getText(texts, 9) }</p>
                                </div>
                                <div className="col">
                                    <img src={ getImage(images, 5) } className="mb-4 img-fluid" alt="Essential Nutrients" width="50%" height="25%" loading="lazy" />
                                    <h5>{ getText(texts, 10) }</h5>
                                    <p>{ getText(texts, 11) }</p>
                                </div>
                                <div className="col">
                                    <img src={ getImage(images, 6) } className="mb-4 img-fluid" alt="Essential Nutrients" width="50%" height="25%" loading="lazy" />
                                    <h5>{ getText(texts, 12) }</h5>
                                    <p>{ getText(texts, 13) }</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    

                    <a id="scroll"></a>

                <Footer />

            </div>

    )

}

export default Home