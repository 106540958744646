import React, { useState, useEffect, useContext } from 'react';

import { useNavigate } from 'react-router-dom';

import { Context } from '../class/Context'

import { Header, Footer } from '../components/imports'
import { APP_NAME } from '../constants/general';

import User from '../class/User'

import { WindowScrollTop } from '../class/Func'

const handleSubmit = async (e, setLoading, setError, navigate, setSession) => {

    e.preventDefault()

    const input = e.target

    if(input.nom.value == ''){

        setError("Veuillez entrer un nom valide")

        WindowScrollTop()

        return false

    }

    setLoading(true)

    const results = await User.register(input);

    setLoading(false)

    if (results.success){

        localStorage.setItem('id', results.data.idU);
        localStorage.setItem('app_token', results.data.app_token);
        localStorage.setItem('nom', results.data.nom);
        localStorage.setItem('prenom', results.data.prenom);
        localStorage.setItem('avatar', results.data.avatar);
        localStorage.setItem('sexe', results.data.sexe);

        setSession(true)

        // Redirect to espace membre

        if(results.data.sexe == 1){
            
            navigate('/abonnement?register=true')

        }else{
            
            navigate('/bienvenue')

        }

    }else{

        setError(results.message)

        WindowScrollTop()

        return false

    }

}

function Inscription() {

    const [loading, setLoading] = useState(false)

    const [error, setError] = useState('')

    const [homme, setHomme] = useState(true)

    const navigate = useNavigate()

    const context = useContext(Context)

    useEffect(() => {
        document.title = "Inscription"
    }, [])

    return (
        <div>

            <Header />

            <main className="m-auto col-6 my-xl-5">
                <form className="form-signin w-100 m-auto col-4 p-4" method="POST" onSubmit={(e) => handleSubmit(e, setLoading, setError, navigate, context.setSession)}>
                    <h1 className="h3 mb-3 fw-normal text-center mb-md-5">Créer un compte sur {APP_NAME}</h1>

                    {(error !== '') && <p className="alert alert-danger" role="alert">{error}</p>}

                    <div>
                        <label>Je suis </label>
                    </div>

                    <div className="mt-2 mb-3 orm-check">
                        <input type="radio" className="form-check-input me-2" name="sexe" id="btnradio1" value="1" defaultChecked onChange={() => setHomme(true)}/>
                        <label className="form-check-label me-5">un homme</label>

                        <input type="radio" className="form-check-input me-2" name="sexe" id="btnradio2" value="2" onChange={() => setHomme(false)}/>
                        <label className="form-check-label">une femme</label>
                    </div>

                    { homme && <p className='alert alert-info'>Un paiement de <strong>2,99 €</strong> est requis après l'inscription pour débuter l'utilisation du service pour les <strong>hommes</strong>.</p> }

                    <div className='row'>

                        <div className="mb-lg-3 col-4">
                            <label className='mb-2'>Kunya</label>
                            <input type="text" className="form-control" name="kunya" placeholder="Abou, Oum, Ibn, Bint ..." required maxLength="16"/>
                        </div>

                        <div className="mb-lg-3 col-4">
                            <label className='mb-2'>Mon nom</label>
                            <input type="text" className="form-control" name="nom" placeholder="Votre nom" required maxLength="128"/>
                        </div>

                        <div className="mb-lg-3 col-4">
                            <label className='mb-2'>Mon prénom</label>
                            <input type="text" className="form-control" name="prenom" id="prenom" placeholder="Votre prénom" required maxLength="128"/>
                        </div>

                    </div>
                    
                    <div className='row'>

                        <div className="mb-lg-3 col-12">
                            <label className='mb-2'>Mon adresse email</label>
                            <input type="email" className="form-control" name="email" id="email" placeholder="Votre adresse email" required maxLength="128"/>
                        </div>

                        <div className="mb-lg-3 col-6 d-none">
                            <label className='mb-2'>Mon numéro de téléphone</label>
                            <input type="text" className="form-control" name="telephone" id="telephone" placeholder="Votre numéro de téléphone" value="000001"/>
                        </div>

                    </div>

                    <div className='row'>

                        <div className="mb-lg-3 col-6">
                            <label className='mb-2'>Mon pays</label>
                            
                            <select name="pays" className="form-control" required>
                                <option>France</option>
                                <option>Algérie</option>
                                <option>Maroc</option>
                                <option>Tunisie</option>
                                <option>Égypte</option>
                                <option>Arabie Saoudite</option>
                                <option>Émirats arabes unis</option>
                                <option>Qatar</option>
                                <option>Koweït</option>
                                <option>Dubai</option>
                                <option>Mali</option>
                                <option>Congo</option>
                                <option>Sénégale</option>
                                <option>Côte d'ivoir</option>
                                <option>Angleterre</option>
                                <option>Espagne</option>
                                <option>Italie</option>
                                <option>Indonésie</option>
                                <option>États-Unis</option>
                                <option>Canada</option>
                            </select>
                        </div>

                        <div className="mb-lg-3 col-6">
                            <label className='mb-2'>Ma ville</label>
                            <input type="text" className="form-control" name="ville" id="ville" placeholder="Votre ville" required maxLength="128"/>
                        </div>

                        <div className="mb-lg-3 col-4 d-none">
                            <label className='mb-2'>Code postal</label>
                            <input type="text" className="form-control" name="postal" id="postal" placeholder="Votre code postal" required value="00001"/>
                        </div>

                    </div>

                    <p className='text-muted fs-6'>* L'identité sera confidentielle et ne sera pas visible sur les profils</p>

                    <div className='row'>

                        <div className="mb-lg-3 col-6">
                            <label className='mb-2'>Mot de passe</label>
                            <input type="password" className="form-control" name="password" id="password" placeholder="********" required minLength="6"/>
                        </div>

                        <div className="mb-lg-3 col-6">
                            <label className='mb-2'>Retappez le mot de passe</label>
                            <input type="password" className="form-control" name="password_2" id="password_2" placeholder="*********" required minLength="6"/>
                        </div>

                    </div>

                    <p className='text-muted fs-6'>* Entrez un mot de passe long et assez difficile à deviner</p>

                    <button className={"btn btn-primary" + (loading ? " disabled" : "")} type="submit">{ (loading) && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> }Créer un compte</button>

                </form>
            </main>

            <Footer />

        </div>
    )

}

export default Inscription