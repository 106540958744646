import React, { useState, useEffect, useContext } from 'react';

import { BrowserRouter as Router, Routes, Route, useParams } from "react-router-dom";

import User from './class/User'

import Home from './pages/Home'
import Inscription from './pages/Inscription'
import Connexion from './pages/Connexion'
import Motdepasse from './pages/Motdepasse'
import Profile from './pages/Profile'
import Page_404 from './pages/Page_404'
import Bienvenue from './pages/Bienvenue'
import Parametres from './pages/Parametres'
import Abonnement from './pages/Abonnement'
import Supprimer from './pages/Supprimer'

import { Context } from './class/Context'
import Acceuil from './pages/Acceuil'
import Discussion from './pages/Discussion'

function App (){

  const [session, setSession] = useState(false)
  const [me, setMe] = useState(false)
  
  useEffect(() => {

    User.connected(setSession, setMe)

  }, [])

  return (

    <Context.Provider value={{session: session, setSession: setSession, me:me, setMe: setMe}}>

      <Router
        //basename={"/react"}
        >
        
        <Routes>

          <Route exact path="/" element={<Home />}></Route>
          <Route path="/inscription" element={<Inscription />}></Route>
          <Route path="/connexion" element={<Connexion />}></Route>
          <Route path="/motdepasse" element={<Motdepasse />}></Route>
          <Route path="/profile/:id" element={<Profile />}></Route>

          <Route path="/bienvenue" element={<Bienvenue />}></Route>
          <Route path="/parametres" element={<Parametres />}></Route>
          <Route path="/abonnement" element={<Abonnement />}></Route>

          <Route path="/supprimer-mon-compte" element={<Supprimer />}></Route>

          <Route path="/acceuil" element={<Acceuil />}></Route>

          <Route path="/discussion/:id" element={<Discussion />}></Route>

          <Route path="*" element={<Page_404 />}></Route>

        </Routes>

      </Router>

    </Context.Provider>
    
  )

}

export default App;
