import React, { useState, useContext } from 'react'

import { Link } from "react-router-dom"

import { AVATAR_ROOT, AVATAR_HOMME, AVATAR_FEMME } from '../constants/general'

import Moment from 'react-moment'
import 'moment/locale/fr';

const me = localStorage.getItem('id')

const Card = (d) => {

    let avatar = (d.user.sexe == 1) ? AVATAR_HOMME : AVATAR_FEMME

    let badge = (d.user.idU == d.user.u1) ? d.user.new_u2 : d.user.new_u1

    if(badge > 0){
        badge = <span className='badge bg-danger'>{badge}</span>
    }else{
        badge = ''
    }

    return (
        <a href={'/discussion/' + d.user.idU} className="user_infos row border border-1 rounded-1 align-items-center text-decoration-none p-2 text-dark mb-2">
            <img className="bd-placeholder-img rounded-circle col-3" src={avatar}></img>
            <div className="col-9">
                <h4 className="fs-5">{d.user.kunya} {d.user.prenom} {badge}</h4>
                <p className="fs-6 my-0">{d.user.pays} ({d.user.ville})</p>
            </div>
        </a>
    );

}

const BigCard = (props) => {

    let avatar = (props.user.sexe == 1) ? AVATAR_HOMME : AVATAR_FEMME

    return (

        <div className="col-4 pb-4">
            
            <div className="card">

                <img src={avatar} className="card-img-top rounded-2" />

                <div className="card-body">
                    <h5 className="card-title">{props.user.kunya} {props.user.prenom}</h5>
                    <p className="card-text">{props.user.pays} ({props.user.ville})</p>
                </div>

                <ul className="list-group list-group-flush">
                    <li className="list-group-item">Age : {props.user.age} ans</li>
                    <li className="list-group-item">Origine : {props.user.origine}</li>
                </ul>

                <div className="card-body">
                    <Link to={"/profile/" + props.user.idU} className="card-link btn btn-primary btn-sm">Profile</Link>
                    <Link to={"/discussion/" + props.user.idU} className="card-link btn btn-info btn-sm text-white"><i className="bi bi-chat-fill me-2"></i> Contacter</Link>
                </div>

            </div>

       </div>

    )

}

const Message = (m) => {

    m = m.message

    return (
        
        (

            m.s !== me

            ?

            <div className="w-100 mb-3 align-items-center">

                <p className="bg-primary text-white m-0 d-inline-block p-1 rounded">{m.msg}</p>
                <Moment interval='1000' locale="fr" fromNow className="badge badge-light text-secondary">{m.d}</Moment>
                
            </div>

            :

            <div className="w-100 mb-3 d-flex justify-content-end align-items-center">

                <Moment interval='1000' className="badge badge-light text-secondary" locale="fr" fromNow>{m.d}</Moment>
                <p className="bg-light text-black m-0 d-inline-block p-1 rounded border border-1 shadow">{m.msg} <p className='text-danger badge m-0'>{m.err}</p></p>

                {
                    m.stt == 0
                    
                    ?
                    
                    <i className="bi bi-hourglass-split mx-2 -secondary" title='Message en attente de validation'></i>

                    : 
                    
                    m.stt == 1

                    ?

                    <i className="bi bi-check text-secondary mx-2" title="Message validé"></i>

                    :

                    <i className="bi bi-check-all text-success mx-2"  title="Message vue"></i>



                }

            </div>

        )

    );

}

export { Card, BigCard, Message };