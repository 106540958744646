import { APP_NAME } from '../constants/general'

const Logout = (navigate, context, redirect) => {

    context.setSession(false)

    localStorage.removeItem('id')
    localStorage.removeItem('app_token')
    localStorage.removeItem('nom')
    localStorage.removeItem('prenom')
    localStorage.removeItem('avatar')
    localStorage.removeItem('sexe')

    navigate('/' + redirect)

}

const WindowScrollTop = () => {

    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
    });

}

const Title = title => {

    document.title = title + " - " + APP_NAME
    
}

export { WindowScrollTop, Title, Logout }