import React, { useState, useEffect, useContext } from 'react';

import { useNavigate  } from 'react-router-dom';

import User from '../class/User'

import { Header, Footer } from '../components/imports'

import { APP_NAME } from '../constants/general'

import { Context } from '../class/Context'

import { Logout } from '../class/Func'

function Supprimer() {

    const [loading, setLoading] = useState(false)

    const [userAllInfos, setUserAllInfos] = useState(false)

    const [me, setMe] = useState(false)

    const navigate = useNavigate();

    const context = useContext(Context)

    const supprimer = async () => {

        setLoading(true)

        let res = await User.supprimer()
    
        if (res.success) {

            alert('Votre compte à été supprimé')
    
            Logout(navigate, context, '')

        } else {
    
            alert('une erreur est servenu !')
    
        }
    
    }

    useEffect(() => {

        User.getAllUserInfos(setUserAllInfos)

        setMe(context.me)

        document.title = "Supprimer mon compte - " + APP_NAME

    }, [])


    return (
        <div>

            <Header />

            <div>
                <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col col-lg-8 mb-4 mb-lg-0">
                            <div className="card mb-3">
                                <div>
                                    <div className="">
                                        <div className="card-body p-4">

                                            {

                                                !loading

                                                ?

                                                <div>

                                                    <h6 className="mb-3 fs-4">Supprimer mon compte Zawaj Muslims</h6>

                                                    <p>Voulez-vous vraiment supprimer votre compte ?</p>

                                                    <p>En supprimant votre compte vous allez perdre</p>

                                                    <ul>
                                                        <li>Vos discussions et messages</li>
                                                        <li>Votre compte et profil</li>
                                                    </ul>

                                                    <p className='alert alert-info'>Si vous êtes toujours abonnés sur <strong>Zawaj Muslims</strong>, veuillez commencer par vous désabonner dans la page <strong>paramètres</strong> et retirer vos moyens de paiement pour supprimer votre compte.</p>

                                                    <p className='alert alert-danger'>Aucun retour arrière ni possible après la suppression.</p>

                                                    <span className='btn btn-danger' onClick={ e => supprimer() }>Supprimer définitivement mon compte</span>

                                                </div>

                                                :

                                                    <div className="d-flex justify-content-center my-5 py-xl-5">
                                                        <div className="spinner-border" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                    </div>
                                            }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            <Footer />

        </div>
    )

}

export default Supprimer